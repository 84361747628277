import { createColor } from 'paletto-color-tools';

export default function imageDataToColorArray2D(imageData) {
  let i = 0;
  let colorArray2D = [];

  for (let r = 0; r < imageData.height; r++) {
    let row = [];
    for (let c = 0; c < imageData.width; c++) {

      let color = createColor({
        r: imageData.data[i],
        g: imageData.data[i+1],
        b: imageData.data[i+2]
      });
      
      row.push(color);
      
      i+=4;
    }
    colorArray2D.push(row);
  }

  return colorArray2D;
}