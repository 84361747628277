import styled from 'styled-components/macro';

const Display = styled.div`
  flex: 1;
  padding: 32px 50px 40px;
  display: grid;
  overflow: hidden;
  justify-content: center;
  align-items: center;
  background: ${props => props.isDragOver ? "hsla(0,0%,100%,.15)" : "hsla(0,0%,100%,0)"};
  transition: background 400ms;

  img {
    filter:
      drop-shadow(4px 4px 8px hsla(0,0%,0%,.35))
      drop-shadow(0px 1px 4px hsla(0,0%,0%,.25))
    ;
    max-width: 100%;
    max-height: 100%;
    overflow: hidden;
    object-fit: contain;
  }
`;

export default Display;