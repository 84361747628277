export default function drawFrame({
  ctx,
  size,
  borderWidth,
  tileWidth
}) {
  // Save the context
  ctx.save();

  let {w,h} = size;

  var gradient = ctx.createLinearGradient(0,0, w, h);

  // Add three color stops
  gradient.addColorStop(0, 'black');
  gradient.addColorStop(.1, 'black');
  gradient.addColorStop(.25, 'dimgray');
  gradient.addColorStop(.5, 'black');
  gradient.addColorStop(.75, 'dimgray');
  gradient.addColorStop(.9, 'black');
  gradient.addColorStop(1, 'black');

  // Fill with gradient
  ctx.fillStyle = gradient;
  ctx.fillRect(0, 0, w, h);

  // BLACKS
  ctx.fillStyle = "hsla(0,0%,0%,.5)";
  ctx.fillRect(                0,       2*tileWidth, w, 1);
  ctx.fillRect(                0, h-(2*tileWidth+1), w, 1);
  ctx.fillRect(      2*tileWidth,                 0, 1, h);
  ctx.fillRect(w-(2*tileWidth+1),                 0, 1, h);

  ctx.fillRect(                0,       5*tileWidth, w, 1);
  ctx.fillRect(                0, h-(5*tileWidth+1), w, 1);
  ctx.fillRect(      5*tileWidth,                 0, 1, h);
  ctx.fillRect(w-(5*tileWidth+1),                 0, 1, h);

  // WHITES
  ctx.fillStyle = "hsla(0,0%,100%,.2)";
  ctx.fillRect(              0,   2*tileWidth+1, w, 1);
  ctx.fillRect(              0, h-(2*tileWidth), w, 1);
  ctx.fillRect(  2*tileWidth+1,               0, 1, h);
  ctx.fillRect(w-(2*tileWidth),               0, 1, h);

  ctx.fillRect(              0,   5*tileWidth+1, w, 1);
  ctx.fillRect(              0, h-(5*tileWidth), w, 1);
  ctx.fillRect(  5*tileWidth+1,               0, 1, h);
  ctx.fillRect(w-(5*tileWidth),               0, 1, h);

  // Fill middle of frame with black
  ctx.fillStyle = "black";
  ctx.fillRect(borderWidth-1, borderWidth-1, w-(2*borderWidth-2), h-(2*borderWidth-2));

  // Restore the context
  ctx.restore();
}