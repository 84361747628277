import distanceBetweenColors from './distanceBetweenColors';

export default function nearestColor(color,palette) {
  let nearest = 0;
  for (let i = 0; i < palette.length; i++) {
    if (palette[i].enabled && distanceBetweenColors(color,palette[i]) < distanceBetweenColors(color,palette[nearest])) {
      nearest = i
    }
  }
  return palette[nearest];
}