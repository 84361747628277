import { createColor } from 'paletto-color-tools';
import legoColors from 'data/lego_colors.json';

let availableColors = [];

for (const [key, value] of Object.entries(legoColors)) {
  availableColors.push(Object.assign({name: key,enabled: true},createColor(value)));
}

export default availableColors;
