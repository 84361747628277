import styled from "styled-components/macro";

const Logo = styled.div`
  padding: 0 1em;
  font-weight: 300;
  display: flex;
  align-items: center;
  font-size: 2em;
  margin-top: .5em;

  img {
    height: 32px;
    margin-right: .25em;
  }
`;

export default Logo;