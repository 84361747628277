import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'containers/App';
import reportWebVitals from 'misc/reportWebVitals';

// import userflow from 'userflow.js'

// userflow.init('ct_yqkqe4za7fgyhhftgl7eitimma')
// userflow.identify('qwerty1234567890', {
//   name: 'Demo Demoson',
//   email: 'demo@example.com',
//   signed_up_at: '2019-12-11T12:34:56Z'
// })

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
