import imageDataToColorArray2D from 'misc/imageDataToColorArray2D';
import nearestColor from 'misc/nearestColor';
import calcQuantError from 'misc/calcQuantError';
import applyQuantError from 'misc/applyQuantError';

export default function drawMosaic({
  inputCtx,
  outputCtx,
  tilesW,
  tilesH,
  availableColors,
  ditherMultiplier,
  maxColors,
  tileWidth,
  borderWidth,
  inputDataURL,
  setColorsInUse,
  setOutputImg
}) {
  console.log("setColorsInUse",setColorsInUse);
  const img = new Image();
  img.onload = function() {

    const canvas = inputCtx.canvas ;
    inputCtx.clearRect(0,0,canvas.width, canvas.height);

    canvas.width = tilesW;
    canvas.height = tilesH;

    let hRatio = canvas.width  / this.width;
    let vRatio =  canvas.height / this.height;
    let ratio  = Math.max( hRatio, vRatio );
    let centerShift_x = ( canvas.width - this.width*ratio ) / 2;
    let centerShift_y = ( canvas.height - this.height*ratio ) / 2;
    inputCtx.drawImage(
      this,
      0,
      0,
      this.width,
      this.height,
      centerShift_x,
      centerShift_y,
      this.width*ratio,
      this.height*ratio
    );

    const imageData = inputCtx.getImageData(0, 0, canvas.width, canvas.height);
    const colorArray2D = imageDataToColorArray2D(imageData);

    let usedColors = {};
    
    let convertedColors2D = [];
    let subsetOfColors = null;
    let done = false;

    while (!done) {
      convertedColors2D = [...colorArray2D];

      let palette = subsetOfColors ? subsetOfColors : availableColors;
      usedColors = {length: 0, colors: {}};

      for (let y = 0; y < convertedColors2D.length; y++) {
        let row = convertedColors2D[y];
        for (let x = 0; x < row.length; x++) {
          let oldPixel = row[x];
          let newPixel = nearestColor(oldPixel,palette);
          convertedColors2D[y][x] = newPixel;

          if (usedColors.colors[newPixel.name]) {
            usedColors.colors[newPixel.name] += 1;
          } else {
            usedColors.colors[newPixel.name] = 1;
            usedColors.length += 1;
          }
          
          // DITHER THE THINGS!
          let qe = calcQuantError(oldPixel,newPixel);

          if (x+1 < row.length) {
            convertedColors2D[y  ][x+1] = applyQuantError( convertedColors2D[y  ][x+1], qe, (ditherMultiplier.start+ditherMultiplier.step*3)/32);
          }

          if (x-1 >= 0 && y+1 < convertedColors2D.length) {
            convertedColors2D[y+1][x-1] = applyQuantError( convertedColors2D[y+1][x-1], qe, (ditherMultiplier.start+ditherMultiplier.step*1)/32);
          }

          if (y+1 < convertedColors2D.length) {
            convertedColors2D[y+1][x  ] = applyQuantError( convertedColors2D[y+1][x  ], qe, (ditherMultiplier.start+ditherMultiplier.step*2)/32);
          }

          if (x+1 < row.length && y+1 < convertedColors2D.length) {
            convertedColors2D[y+1][x+1] = applyQuantError( convertedColors2D[y+1][x+1], qe, ditherMultiplier.start/32);
          }
          // END OF DITHERING!
        }
      }

      if(usedColors.length <= maxColors) {
        // All good, nothing to do but break the loop
        done = true
      } else {
        // Let's pick the top [maxColors] of the colors and try again
        let colors = {...usedColors.colors};

        let sortedColors = Object.keys( colors ).sort(function( a, b ) {
          return colors[ b ] - colors[ a ];
        });

        sortedColors.splice(maxColors);

        subsetOfColors = availableColors.filter(color => sortedColors.includes(color.name));

      }
    }

    for (let y = 0; y < convertedColors2D.length; y++) {
      let row = convertedColors2D[y];
      for (let x = 0; x < row.length; x++) {
        let color = convertedColors2D[y][x];
        outputCtx.beginPath();
        outputCtx.arc(
          x * tileWidth + tileWidth/2 + borderWidth,
          y * tileWidth + tileWidth/2 + borderWidth,
          tileWidth/2,
          0,
          2 * Math.PI,
          false
        );

        outputCtx.fillStyle = color.hex;
        outputCtx.fill();

      }
    }

    setColorsInUse(usedColors);
    setOutputImg(outputCtx.canvas.toDataURL('image/png'));

  };
  img.src = inputDataURL;
}