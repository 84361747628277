export default function applyQuantError(color,quantError,m) {
  let { r, g, b } = color;

  let qeR = quantError.r * m;
  let qeG = quantError.g * m;
  let qeB = quantError.b * m;

  r = parseInt(color.r + qeR);
  g = parseInt(color.g + qeG);
  b = parseInt(color.b + qeB);

  return {r,g,b};
}