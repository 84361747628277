import {
  useEffect,
  useRef,
  useState
}                     from 'react';
import styled         from 'styled-components/macro';

import Dimensions     from 'components/Dimensions';
import SettingsPanel  from 'components/SettingsPanel';
import Display        from 'components/Display';
import Info           from 'components/Info';
import Logo           from 'components/Logo';
import Main           from 'components/Main';
import HiddenCanvas   from 'components/HiddenCanvas';

import initialDataURL from 'data/initialDataURL';
import drawFrame      from 'drawUtils/drawFrame';
import drawMosaic     from 'drawUtils/drawMosaic';

import colors         from 'misc/availableColors';
import formatNumber   from 'misc/formatNumber';

import brisaicLogo    from 'svg/brisaic-logo.svg';

const AppContainer = styled.div`
  display: flex;
  height: 100vh;
  background: radial-gradient(ellipse at top, hsl(206,26%,20%), hsl(216,16%,15%));
  color: white;
  background-repeat: repeat;

  a {
    color: hsl(50,93%,53%);
  }
`;

export default function App() {

  // REFS
  const inputCanvasRef = useRef();
  const outputCanvasRef = useRef();
  
  // CONSTANTS
  // Base sizes in LEGO Units
  const MM_PR_TILE = 8;     // As long as tiles are 1 LEGO Unit, this should be 8 (mm)
  const PX_PR_TILE = 24;     // This only affects the resolution of the output image
  const TILES_PR_BORDER = 1;     // Width of border (frame) counted in measures of tiles
  const TILES_PR_PANEL = 16; // A panel is the unit used to set the size of the mosaic
  
  
  // Initial values
  const INITIAL_NUM_OF_PANELS_W = 5;
  const INITIAL_NUM_OF_PANELS_H = 5;
  const INITIAL_MAX_COLORS = 24;

  // STATES
  const [ dragOver, setDragOver ]                 = useState(false);
  const [ outputCtx, setOutputCtx ]               = useState(null);
  const [ inputCtx, setInputCtx ]                 = useState(null);
  const [ panels, setPanels ]                     = useState({w: INITIAL_NUM_OF_PANELS_W, h: INITIAL_NUM_OF_PANELS_H});
  const [ pictureSizePx, setPictureSizePx ] = useState({
    w: INITIAL_NUM_OF_PANELS_W*TILES_PR_PANEL*PX_PR_TILE,
    h: INITIAL_NUM_OF_PANELS_H*TILES_PR_PANEL*PX_PR_TILE
  });
  const [ totalSizePx, setTotalSizePx ] = useState({
    w: INITIAL_NUM_OF_PANELS_W*TILES_PR_PANEL*PX_PR_TILE + TILES_PR_BORDER*PX_PR_TILE*2,
    h: INITIAL_NUM_OF_PANELS_H*TILES_PR_PANEL*PX_PR_TILE + TILES_PR_BORDER*PX_PR_TILE*2
  });
  const [ totalSizeMm, setTotalSizeMm ] = useState({
    w: INITIAL_NUM_OF_PANELS_W*TILES_PR_PANEL*MM_PR_TILE + TILES_PR_BORDER*MM_PR_TILE*2,
    h: INITIAL_NUM_OF_PANELS_H*TILES_PR_PANEL*MM_PR_TILE + TILES_PR_BORDER*MM_PR_TILE*2
  });
  const [ outputImg, setOutputImg ]               = useState();
  const [ availableColors, setAvailableColors ]   = useState(colors);
  const [ inputDataURL, setInputDataURL ]         = useState(initialDataURL);
  const [ colorsInUse, setColorsInUse ]           = useState();
  const [ ditherMultiplier, setDitherMultiplier ] = useState({start: 1, step: 2});
  const [ maxColors, setMaxColors ]               = useState(INITIAL_MAX_COLORS);
  const [ showSettings, setShowSettings ]         = useState(false);


  // Update picture size and total size when base plates changes
  useEffect(() => {
    setPictureSizePx({
      w: panels.w*TILES_PR_PANEL*PX_PR_TILE,
      h: panels.h*TILES_PR_PANEL*PX_PR_TILE,
    })
    setTotalSizePx({
      w: panels.w*TILES_PR_PANEL*PX_PR_TILE + TILES_PR_BORDER*PX_PR_TILE*2,
      h: panels.h*TILES_PR_PANEL*PX_PR_TILE + TILES_PR_BORDER*PX_PR_TILE*2,
    })
    setTotalSizeMm({
      w: panels.w*TILES_PR_PANEL*MM_PR_TILE + TILES_PR_BORDER*MM_PR_TILE*2,
      h: panels.h*TILES_PR_PANEL*MM_PR_TILE + TILES_PR_BORDER*MM_PR_TILE*2,
    })
  },[panels])

  // Set the output context when the output canvas is ready
  useEffect(() => {
    setOutputCtx(outputCanvasRef.current.getContext("2d"));
  },[outputCanvasRef]);

  // Set the input context when the input canvas is ready
  useEffect(() => {
    setInputCtx(inputCanvasRef.current.getContext("2d"));
  },[inputCanvasRef]);
  
  useEffect(() => {
    if (outputCtx) {

      drawFrame({
        ctx: outputCtx,
        size: totalSizePx,
        borderWidth: TILES_PR_BORDER*PX_PR_TILE,
        tileWidth: PX_PR_TILE,
      });

      // Draw the uploaded image!
      inputDataURL && drawMosaic({
        inputCtx,
        outputCtx,
        tilesW: panels.w*TILES_PR_PANEL,
        tilesH: panels.h*TILES_PR_PANEL,
        availableColors,
        ditherMultiplier,
        maxColors,
        tileWidth: PX_PR_TILE,
        borderWidth: TILES_PR_BORDER*PX_PR_TILE,
        inputDataURL,
        setColorsInUse: setColorsInUse,
        setOutputImg: setOutputImg
      })
    }
  },[
    outputCtx,
    totalSizePx,
    inputDataURL,
    panels,
    inputCtx,
    availableColors,
    ditherMultiplier,
    maxColors,
    PX_PR_TILE,
    pictureSizePx,
  ]);

  function handleUpload(event,drop = false) {
    setDragOver(false);
    event.stopPropagation();
    event.preventDefault();
    const file = drop ? event.dataTransfer.files[0] : event.target.files[0];

    if (!file || !file.type.match(/image.*/)) return;

    const reader = new FileReader();
    reader.onload = function(event) {
      
      const img = new Image();
      img.onload = function(){
        let inputW = this.width;
        let inputH = this.height;
        let ratio = 160 / Math.min( inputW, inputH ); // why 160? Maybe just downsizing for the hell of it?

        inputCtx.canvas.width = inputW * ratio;
        inputCtx.canvas.height = inputH * ratio;

        inputCtx.drawImage(
          this,             // image
          0,                // source x
          0,                // source y
          this.width,       // source width
          this.height,      // source height
          0,                // dist x
          0,                // dist y
          this.width*ratio, // dist width
          this.height*ratio // dist height
        );
        
        setInputDataURL(inputCtx.canvas.toDataURL("image/png"));
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  }

  return (
    <>
    <HiddenCanvas ref={inputCanvasRef}/>
    <HiddenCanvas ref={outputCanvasRef} width={totalSizePx.w} height={totalSizePx.h}/>
    <AppContainer>
      <Main>
        <Logo onClick={() => setShowSettings(!showSettings)}><img src={brisaicLogo} alt="Brisaic Logo"/>Brisaic</Logo>
        <Display
          isDragOver  = {dragOver}
          onDragEnter = {(e) => e.preventDefault()}
          onDragLeave = {(e) => e.preventDefault()}
          onDragOver  = {(e) => e.preventDefault()}
          onDrop      = {(e) => handleUpload(e,true)}
        >
          <img src={outputImg} alt="Brisaic Mosaic"/>
        </Display>
        <Info>
          <Dimensions w={totalSizeMm.w} h={totalSizeMm.h}/> {` • ${formatNumber((pictureSizePx.w/PX_PR_TILE)*(pictureSizePx.h/PX_PR_TILE))} tiles`} • <a href={outputImg} download="brisaic.png">Download image</a>
        </Info>
      </Main>
      
      {showSettings && <SettingsPanel
        colorsInUse        = {colorsInUse}
        selectedSize       = {panels}
        onSizeChange       = {setPanels}
        onUpload           = {handleUpload}
        availableColors    = {availableColors}
        setAvailableColors = {setAvailableColors}
        multiplier         = {ditherMultiplier}
        setMultiplier      = {setDitherMultiplier}
        maxColors          = {maxColors}
        setMaxColors       = {setMaxColors}
      />}
    </AppContainer>
    </>
  );
}