// import { useState } from 'react';
import styled from 'styled-components/macro';

const Settings = styled.div`
  width: 320px;
  /* background: darkslategray; */
  /* color: white; */
  height: 100%;
  padding: 2em;
  overflow-y: auto;
`;

const Ol = styled.ol`
  margin: 0;
  list-style: none;
  padding: 0;
  font-size: .8em;
`;

const Li = styled.li`
  margin-top: .25em;
  color: ${ props => props.zero ? "hsla(0,0%,100%,.25)" : "inherit"};
`;

export default function SettingsPanel({
  selectedSize,
  onSizeChange,
  onUpload,
  colorsInUse = {colors:{}},
  availableColors,
  setAvailableColors,
  multiplier,
  setMultiplier,
  maxColors,
  setMaxColors
}) {

  let selectedSizeStr = selectedSize.w + "x" + selectedSize.h;
  function handleSizeChange(sizeStr) {
    let arr = sizeStr.split("x");
    let w = parseInt(arr[0]);
    let h = parseInt(arr[1]);
    onSizeChange({w,h});
  }

  let selectedMagicMultiplier = multiplier.start + "+" + multiplier.step;
  function handleMagicChange(magicStr) {
    let arr = magicStr.split("+");
    let start = parseInt(arr[0]);
    let step = parseInt(arr[1]);
    setMultiplier({start,step});
  }

  function renderColors(palette,usedColors) {
    let liArray = [];

    function handleChange(index,enabled) {
      const newPalette = [...palette];
      newPalette[index].enabled = enabled;
      setAvailableColors(newPalette)
    }

    for (let i = 0; i < palette.length; i++) {
      const color = palette[i];

      liArray.push(
        <Li key={color.name} zero={!usedColors.colors.hasOwnProperty(color.name)}>
          <input
            type="checkbox"
            checked={color.enabled}
            onChange={() => handleChange(i,!color.enabled)}
          />
          {color.name} ({usedColors.colors[color.name] || 0})
        </Li>
      ) 
    }

    return liArray;
  }

  return (
    <Settings>
      <fieldset>
        <legend>Upload</legend>
        <input type="file" onChange={(event) => onUpload(event)}/>
      </fieldset>
      
      <fieldset>
        <legend>Mosaic size</legend>
        <select value={selectedSizeStr} onChange={(e) => handleSizeChange(e.target.value)}>
          <option disabled>Recommended</option>
          <option value="8x5">8 × 5 panels (104 × 66 cm)</option>
          <option value="5x8">5 × 8 panels (66 × 104 cm)</option>
          <option disabled>Square</option>
          <option value="3x3">3 × 3 panels (40 × 40 cm)</option>
          <option value="4x4">4 × 4 panels (53 × 53 cm)</option>
          <option value="5x5">5 × 5 panels (66 × 66 cm)</option>
          <option value="6x6">6 × 6 panels (78 × 78 cm)</option>
          <option value="7x7">7 × 7 panels (91 × 91 cm)</option>
          <option value="8x8">8 × 8 panels (104 × 104 cm)</option>
          <option value="9x9">9 × 9 panels (117 × 117 cm)</option>
          <option disabled>Panorama</option>
          <option value="10x4">10 × 4 panels (130 × 53 cm)</option>
        </select>
      </fieldset>
      <fieldset>
        <legend>Advanced settings</legend>
        Magic shading options
        <select value={selectedMagicMultiplier} onChange={(e) => handleMagicChange(e.target.value)}>
          <option disabled>Magic shading options</option>
          <option value="0+0">No magic</option>
          <option value="1+2">A little magic</option>
          <option value="2+3">Maybe too much magic</option>
          <option value="2+4">Probably too much magic</option>
        </select>
        <br/>
        <br/>
        <div>
          Max colors: {maxColors}<br/>
          <input 
            type="range"
            min={2}
            max={40}
            value={maxColors}
            onChange={(e) => setMaxColors(parseInt(e.target.value))}
          />
        </div>
      </fieldset>

      <fieldset>
      <legend>Colors {colorsInUse.length > 0 && "(" + colorsInUse.length + ")"}</legend>
          <Ol>
            { renderColors(availableColors,colorsInUse) }
          </Ol>
      </fieldset>
    </Settings>
  )
}