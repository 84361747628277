// Dependencies
import { useState } from 'react';
import styled from 'styled-components/macro';

// Other imports

// Components

const UnitSwitcher = styled.span`
  cursor: pointer;
  border-bottom: 2px dotted darkgray;
`;

export default function Dimensions({w,h}) {
  const [ saneUnits, setSaneUnits ] = useState(true);
  let cmW = Math.round(w / 10);
  let cmH = Math.round(h / 10);
  let inW = Math.round(w / 25.4);
  let inH = Math.round(h / 25.4);
  let remainingInchesW = inW % 12;
  let remainingInchesH = inH % 12;
  let ftW = (inW - remainingInchesW) / 12;
  let ftH = (inH - remainingInchesH) / 12;
  // let something = Math.round(totalSizePx.w / 10)} × ${Math.round(totalSizePx.h / 10) + "cm";
  return (
    <UnitSwitcher onClick={() => setSaneUnits(!saneUnits)}>
      {
        saneUnits
        ? `${cmW} × ${cmH} cm`
        : `${ftW > 0 ? ftW + " ft" : ""} ${remainingInchesW} in × ${ftH > 0 ? ftH + " ft" : ""} ${remainingInchesH} in`
      }
    </UnitSwitcher>
  )
}
